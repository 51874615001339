@import "./assets/styles/typography.css";
@import './assets/styles/flexboxgrid.min.css';

:root {
  --dark-slate-blue: #0f3343;
  --white: #fff;
  --cool-grey: #a2afb5;
  --very-light-pink: #d8d8d8;
  --very-light-pink-two: #d9d9d9;
  --blue-grey: #7390a0;
  --black: #363636;
  --greyish-brown: #434343;
  --cerulean: #0084e1;
  --salmon: #f96969;
  --very-light-pink: #f3f3f3;
  --pinkish-grey: #c5c2c2;
  --ocean-green: #26C485;
  --viridian-green: #32908F;
  --medium-aquamarine: #71DDB2;
  --honey-yellow: #FFAE03;
  --amber: #ff6700;
  --maximum-green: #698F3F;
  --emerald: #00916E;
  --red: #f01414;
}

body {
  overflow-x: hidden !important;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
}

.container {
  max-width: 960px;
  /* margin: 5%; */
  margin: 0 auto;
  /* overflow-x: hidden !important; */
  padding: 5% 6%;
}

.flex {
  display: flex;
}

.margin-auto {
  margin: auto 0;
}

.row {
  justify-content: center;
}

.col-xs-12 {
  padding: 2%;
}

.pl-2 {
  padding-left: 2em;
}

.pr-2 {
  padding-right: 2em;
}

.prl-1 {
  padding-right: 1em;
  padding-left: 1em;
}

.ptb-2 {
  padding-top: 3%;
  padding-bottom: 6%;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.mb-30 {
  margin-bottom: 2rem;
}

.image-banner {
  left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
}

.flex-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

/* .Were-creating-a-mag {
  width: 447px;
  height: 170px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--greyish-brown);
} */

.Were-creating-a-mag .text-style-1 {
  font-weight: bold;
}

img {
  display: flex;
  margin: auto;
}

img.pirate {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  margin: auto;
}

img.img-logo {
  margin: 0;
}

button {
  width: 206px;
  height: 48px;
  border-radius: 8px;
  background-color: var(--cerulean);
  color: white;
  border: none;
  margin: 2% 0;
  cursor: pointer;
}

.connect-wallet-button {
  width: 100%;
}

button:disabled {
  background-color: var(--pinkish-grey);
  cursor: default;
}

.disabled {
  cursor: default !important;
  color: var(--pinkish-grey);
}

.disabled:hover {
  border: none !important;
}

.mint-button {
  display: block;
  margin: 0 auto;
}

button > h5 {
  margin: 0;
}

.login-button {
  width: 210px;
  height: 43px;
  /* margin: 10px 0 29px 103px; */
  padding: 8px 11px 7px 13px;
  border-radius: 8px;
  /* background-color: var(--very-light-pink); */
  display: flex;
  align-items: center;
  margin: auto auto;
  justify-content: center;
}

.login-button-icon {
  width: 28px;
  height: 28px;
  float: left;
  margin: 0 13px 0 0;
}

.social-link {
  width: 48px;
  height: 48px;
  margin: auto;
}

/* Membership */

.membership-icon {
  height: 73px;
}

.membership-step-description {
  width: 55%;
  margin: auto;
}

.all-members-lion {
  margin-top: 5%;
}

.nft-guide-description {
  margin: 2em;
}

/* .membership-container {
  width: 400px;
  padding: 30px 9px 44px 18px;
  border-radius: 8px;
  background-color: var(--very-light-pink);
} */

.membership-container {
  /* width: 400px; */
  padding: 30px 30px 44px 30px;
  border-radius: 8px;
  background-color: #f3f3f3;
  background-color: var(--very-light-pink);
}

.membership-container-left {
  margin-right: 4%;
}

.membership-container-right {
  margin-left: 4%;
}

.membership-container-center {
  margin: 0 2em;
}

/* Membership end  */

.mb-0 {
  margin-bottom: 0;
}

.card-true {
  width: 185px;
  margin: 0;
}

.padding-2 {
  padding: 2em;
}

.giveaway-icon {
  height: 3em;
}

.giveaway-icon-parachute {
  height: 4em;
}

.giveaway-icon-hotel {
  padding-top: 1.5em;
}

.column {
  display: flex;
  flex-direction: column;
}

.col {
  flex: 1;
}

.justify-left {
  display: flex;
  justify-content: flex-end;
}

.justify-left img {
  margin: 0;
}

.col-roadmap-padding-9 {
  min-height: 9em;
}

.col-roadmap-padding-4 {
  min-height: 4em;
}

.events-container {
  height: 80px;
  margin-bottom: 2em;
}

.container-event-info {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px 9px 20px 8px;
  border-radius: 8px;
  background-color: var(--very-light-pink);
}

.container-social-partner {
  height: 5em;
  padding: 18px 9px 20px 8px;
  border-radius: 8px;
  background-color: var(--very-light-pink);
}

.value-flex-column {
  display: flex;
  flex-direction: column;
  margin-top: 6em;
}

.team-lion-image {
  width: 93.8px;
  height: 100px;
  object-fit: contain;
}

.component-container {
  scroll-margin-top: 3em;
  margin-top: 5.5em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-5 {
  margin-top: 5em;
}

.mt-6 {
  margin-top: 6em;
}

.mtb-1 {
  margin: 1em 0;
}

.oval {
  width: 17px;
  height: 17px;
  margin: 0 11px -5px 0;
  display: inline-block;
  border-radius: 50px;
}

.small-oval {
  width: 10px;
  height: 10px;
  margin: 0 11px 0px 10px;
  display: inline-block;
  border-radius: 50px
}

.bg-green {
  background-color: var(--ocean-green);
}

.bg-red {
  background-color: var(--red);
}

.bg-orange {
  background-color: var(--amber);
}

.scores-image {
  width: 100%;
}

.scroll-button-container {
  position: fixed;
  width: 105vw;
  left: -50px;
  bottom: 0px;
  height: 4.5rem;
  font-size: 3rem;
  /* z-index: 1; */
  background-color: white;
  border-top: 1px solid var(--very-light-pink);
}

.scroll-to-top-button {
  display: flex;
  margin: auto;
}

@media (max-width:768px) {
  .scroll-button-container {
    width: 100vw;
    left: 0px;
    height: 5rem;

  }

  .scroll-to-top-button-action {
    margin: 4% 4% !important;
  }

  .scroll-information {
    margin-block-start: 1em;
  }
}

/* Spinner */

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--salmon);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

/* End Spinner */

.step {
  margin: auto;
}

.button-container-left {
  margin-top: 9%;
}

/* MOBILE SPECIFIC */
/* Extra Small Devices, Phones */
@media (max-width:480px) {
  .container {
    margin: 5%;
  }

  h1 {
    line-height: 1.2em;
  }

  button {
    margin: 4% 0;
  }

  .col-xs-12 {
    margin-block-start: 6%;
  }

  .col-sm-6 {
    margin-block-start: 6%;
  }

  .component-container {
    margin-top: 2.5em;
  }

  .events-container {
    padding: 0.2em;
  }

  .container-social-partner {
    height: 7.5em;
  }

  .membership-container-left {
    margin-right: 0;
    margin-bottom: 4%;
  }

  .membership-container-right {
    margin-left: 0;
    margin-top: 4%;
  }
}

/* Roadmap */

@media (min-width:768px) {
  .roadmapContainerMobile {
    display: none;
  }
}

@media (max-width:767px) {
  .roadmapContainer {
    display: none;
  }
}
/* End Roadmap */

/* Tabs */

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #bbbbbb;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  cursor: pointer;
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0.3rem 2rem;
  color: var(--greyish-brown);
}

.react-tabs__tab--selected {
  background: #fff;
  border-radius: 5px 5px 0 0;
  color: var(--salmon) !important;
  border: none !important;
  border-bottom: 2px solid var(--salmon) !important;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab-panel {
  display: none;
  min-height: 19em;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab:focus {
  outline: var(--salmon);
}

.react-tabs__tab--selected {
  color: var(--salmon) !important;
  border: none !important;
  border-bottom: 2px solid var(--salmon) !important;
}

/* End Tabs */

.spotify-playlist {
  border-radius: 12px;
}

/* Footer  */

.social-icons-follow {
  margin: auto;
}

@media (min-width:480px) {
  .social-icons-follow {
    width: 65%;
  }
}

@media (max-width:480px) {
  .social-link {
    width: 35px;
    height: 35px;
  }
}