@font-face {
  font-family: Poppins;
  src: url(fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Poppins;
  src: local(Poppins-SemiBold),
    /* url(fonts/Poppins-SemiBold.woff2) format("woff2"),
    url(fonts/Poppins-SemiBold.woff) format("woff"), */
    url(fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Poppins;
  src: local(Poppins-Bold),
    /* url(fonts/Poppins-Bold.woff2) format("woff2"),
    url(fonts/Poppins-Bold.woff) format("woff"), */
    url(fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

h1 {
  font-family: Poppins;
  font-size: 3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}

h2 {
  font-family: Poppins;
  font-size: 1.7rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--salmon);
}

h3 {
  font-family: Poppins;
  font-size: 1.3rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f2f2f;
  margin-block-start: 1em;
  margin-block-end: 0;
}

h4 {
  font-family: Poppins;
  font-size: 1.1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--greyish-brown)
}

h5 {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}

a {
  color: var(--salmon);
  text-decoration: none;
}

.no-margin {
  margin-block: 0;
}

.mb-1 {
  margin-block: 1.2em;
}

.black {
  color: var(--black);
}

.white {
  color: var(--white);
}

.cerulean {
  color: var(--cerulean);
}

.greyish-brown {
  color: var(--greyish-brown);
}

.green {
  color: var(--emerald);
}

.orange {
  color: var(--amber);
}

.red {
  color: var(--red);
}

.badge {
  --tw-text-opacity: 1;
  --tw-bg-opacity: 1;
  border-radius: 0.25rem;
  padding: 0.125rem 0.625rem;
}

.red-badge {
  color: rgb(155 28 28/var(--tw-text-opacity));
  background-color: rgb(253 232 232/var(--tw-bg-opacity));
}

.green-badge {
  color: rgb(3 84 63/var(--tw-text-opacity));
  background-color: rgb(222 247 236/var(--tw-bg-opacity));
}

.orange-badge {
  color: rgb(114 59 19/var(--tw-text-opacity));
  background-color: rgb(253 246 178/var(--tw-bg-opacity));
}

p {
  margin-block: 0.2em;
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--greyish-brown)
}

ul {
  padding-inline-start: 5%;
}

li {
  font-size: 0.8rem;
  line-height: 1.5em;
  list-style-position: outside;
  padding-inline-start: 3%;
  /* line-height: 2em; */
}

.ul-spacing li {
  line-height: 2em;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.salmon {
  color: var(--salmon);
}

.small {
  font-size: 0.8rem;
}

.extra-small {
  font-size: 0.7rem;
}

.semi-bold {
  font-weight: 600;
}

.p-semi-bold {
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--greyish-brown);
}

.p-black {
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--black);
}

.p-cerulean {
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--cerulean);
}

.p-greyish-brown {
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--greyish-brown);
}

.p-small-bold-greyish-brown {
  font-family: Poppins;
  font-size: 0.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: var(--greyish-brown);
}

.p-small {
  font-family: Poppins;
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--greyish-brown);
}

.t-center {
  text-align: center;
}

.left-aligned {
  text-align: left;
}

.nav-links {
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--salmon);
}
