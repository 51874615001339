nav {
	position: sticky;
	z-index: 10;
	top: 0;
	padding: 0;
	background-color: white;
	max-height: 4em;
  text-align: right;
  margin-right: 2.3em;
  min-height: 4em;
  max-width: 100vw;
  margin: 0 auto;
  /* left: 50%;
	margin-left: -50vw;
	margin-right: -5vw;
	max-width: 100vw;
	/* position: relative; */
	/* right: 50%;
	width: 100vw; */

}

.nav-link-menu {
  max-width: 960px;
  margin: 0 auto;
}

.nav-social-link {
  margin: 0.3em auto !important;
}

#NFTs {
  width: 44px;
}

nav span {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--salmon);
	border-bottom: 1px solid transparent;
	transition: border-bottom 0.2s ease;
	transition-delay: 0.25s;
	margin: 1.8em 1.5em 0em 1.5em;
	padding-bottom: 0.3em;
}

#dropdownLinkContainer {
  display: inline-flex;
  flex-direction: column;
}

#dropdownLinks {
  display: inline-flex;
  flex-direction: column;
  background: white;
}

#dropdownLinks span {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--salmon);
	border-bottom: 1px solid transparent;
	transition: border-bottom 0.2s ease;
	transition-delay: 0.25s;
	margin: 0.3em;
	padding-bottom: 0.3em;

}

.dropdown {
  display: inline-block;
}

.nav-span {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--salmon);
	border-bottom: 1px solid transparent;
	transition: border-bottom 0.2s ease;
	transition-delay: 0.25s;
	margin: 2em;
	padding-bottom: 0.3em;
}

nav span:hover {
	cursor: pointer;
	border-bottom: 1px solid var(--salmon);
}

nav span:hover i {
	color: gold;
}

nav span.activeClass i {
	color: gold;
}

nav span.activeClass {
	border-bottom-color: var(--salmon);
}

#dropdownLinks span:hover {
	cursor: pointer;
	border-bottom: 1px solid var(--salmon);
}

#dropdownLinks span:hover i {
	color: gold;
}

#dropdownLinks span.activeClass i {
	color: gold;
}

#dropdownLinks span.activeClass {
  border-bottom-color: var(--salmon);
}

.logo {
  display: inline-block;
  float: left;
}

.logo-image {
  width: 60%;
}

.margin-0 {
  margin: 0;
}

/* MOBILE SPECIFIC */
/* Extra Small Devices, Phones */
@media (max-width:856px) {
  .menu-link {
    background-color: transparent;
    width: auto;
    margin: 2% 2% 0 0;
  }
}

@media (max-width:768px) {
  #NFTs {
    width: inherit;
  }

  .nav-link-menu {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: auto;
  }


  .menu-link {
    background-color: transparent;
    width: auto;
    margin: 4% 0 0 0;
  }

  #dropdownLinks {
    display: flex;
    flex-direction: column;
    text-align: right;
    background: white;
  }

  #dropdownLinks span {
    text-align: right
  }

  .metamask-deeplink {
    margin: 1em 0.5em 0em 1.5em
  }

  .action-button-navlink.cerulean {
    margin: 0em;
  }

  nav span {
    text-align: right;
    margin: 1em 0.5em 0em 1.5em;
  }

  nav {
    max-width: 93%;
  }

  .logo {
    display: flex;
    margin: 4% 0 0 0;
  }

  .logo-image {
    width: 50%;
    margin: auto;
  }

  .margin-0 {
    margin: 0;
  }
}

/* MOBILE SPECIFIC */
/* Extra Small Devices, Phones */
@media (max-width:480px) {
  
}